<template>
  <div>
    <el-row class="vg_mb_16">
      <el-col>
        <el-button plain size="small" type="primary" :disabled="disableFlag" @click="clickConfirm">生成</el-button>
        <el-button plain size="small" type="danger" @click="clickCancel">取消</el-button>
      </el-col>
    </el-row>
    <el-table :data="generateMtrlForm" border @selection-change="handleSelectionChange" ref="mtrbTableRef">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="我司货号">
        <template v-slot="scope">
          <el-input
            v-model="generateMtrlForm[scope.$index].prod_no"
            disabled
            maxlength="30"
            size="mini"
            show-word-limit
            placeholder="暂无我司货号"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="货号后缀">
        <template v-slot="scope">
          <el-input
            v-model="generateMtrlForm[scope.$index].prod_poststfix"
            size="mini"
            disabled
            maxlength="2"
            show-word-limit
            placeholder="暂无货号后缀"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="材料名称">
        <template v-slot="scope">
          <el-input
            v-model="generateMtrlForm[scope.$index].mtrb_name"
            size="mini"
            disabled
            show-word-limit
            placeholder="暂无部件名称"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="客户货号">
        <template v-slot="scope">
          <el-input
            v-model="generateMtrlForm[scope.$index].prod_cust_no"
            size="mini"
            disabled
            show-word-limit
            placeholder="暂无客户货号"
          ></el-input>
        </template>
      </el-table-column>
      <!-- <el-table-column label="中文名称">
        <template v-slot="scope">
            <el-input  v-model="generateMtrlForm[scope.$index].prod_name" size="mini" disabled show-word-limit placeholder="暂无中文名称"></el-input>
        </template>
      </el-table-column> -->
      <el-table-column label="规格">
        <template v-slot="scope">
          <el-input
            v-model="generateMtrlForm[scope.$index].mtrb_spec"
            size="mini"
            disabled
            show-word-limit
            placeholder="暂无规格"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="需申购数量">
        <template v-slot="scope">
          <el-input
            v-model="generateMtrlForm[scope.$index].scon_mtrb_apply"
            @change="mtrbApplyChange(scope.$index)"
            size="mini"
            oninput="value=value.replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || ''"
            maxlength="10"
            show-word-limit
            placeholder="暂无需申购数量"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="已申购数量">
        <template v-slot="scope">
          <el-input
            v-model="generateMtrlForm[scope.$index].scon_mtrb_applied"
            size="mini"
            disabled
            show-word-limit
            placeholder="暂无已申购数量"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="预计数量">
        <template v-slot="scope">
          <el-input
            v-model="generateMtrlForm[scope.$index].scon_mtrb_num"
            size="mini"
            disabled
            show-word-limit
            placeholder="暂无预计数量"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="采购单价">
        <template v-slot="scope">
          <el-input
            v-model="generateMtrlForm[scope.$index].prod_mtrb_price"
            size="mini"
            disabled
            maxlength="7"
            show-word-limit
            placeholder="暂无采购单价"
          ></el-input>
        </template>
      </el-table-column>
      <!--@change="suppSelectChange(scope.$index)"-->
      <el-table-column label="供应商简称">
        <template v-slot="scope">
          <el-select v-model="generateMtrlForm[scope.$index].supp_id" size="mini" filterable show-word-limit placeholder="暂无供应商简称">
            <el-option v-for="item in suppList" :key="item.supp_id" :value="item.supp_id" :label="item.supp_abbr"></el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { suppAPI } from '@/api/modules/supp';
import { modrAPI } from '@/api/modules/modr';
export default {
  name: 'GenerateMtrb',
  props: {
    generateMtrlForm: Array,
    sconId: {
      type: Number,
      required: true
    },
    sconNo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      suppList: [],
      selectionsList: [],
      copy: JSON.parse(JSON.stringify(this.generateMtrlForm)),
      disableFlag: false
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getSupp();
    },
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
      // this.selectRows()
    },
    //只能选择同一个供应商
    // handelSelectChange(selection,row){
    //     if(selection.length>=2){
    //         if(selection[0].supp_id!==row.supp_id){
    //             this.$refs.mtrbTableRef.toggleRowSelection(row,false)
    //         }
    //     }
    // },
    selectRows() {
      let temp = false;
      for (let i = 1; i < this.selectionsList.length; i++) {
        if (this.selectionsList[0].supp_id !== this.selectionsList[i].supp_id) {
          temp = true;
        } else {
          temp = false;
        }
      }
      if (temp === true) {
        this.disableFlag = true;
      } else {
        this.disableFlag = false;
      }
    },
    //确定生成
    clickConfirm() {
      if (this.selectionsList.length <= 0) {
        return this.$message.warning('至少选择一条数据');
      }
      let appliFlag = false;
      this.selectionsList.forEach(item => {
        if (item.scon_mtrb_num === item.scon_mtrb_applied) {
          appliFlag = true;
        }
      });
      if (appliFlag) {
        return this.$message.warning('申购已完成');
      }
      // let idFlag = false;
      // for(let i=0;i<this.selectionsList.length;i++){
      //     for(let j=0;j<this.selectionsList.length;j++){
      //         if(this.selectionsList[i].scon_mtrb_id === this.selectionsList[j].scon_mtrb_id){
      //         idFlag = true;
      //         }
      //     }
      // }
      // if(idFlag){
      //   return this.$message.warning('')
      // }
      this.$confirm('确定生成?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let temp = {};
          temp.scon_id = this.sconId;
          temp.scon_mtrb_list = this.selectionsList;
          post(modrAPI.addModr, temp)
            .then(res => {
              if (res.data.code === 0) {
                this.$message({
                  type: 'success',
                  message: '生成成功'
                });
                this.clickCancel();
                const permId = 128;
                this.jump('/modr_list', { perm_id: permId, scon_no: this.sconNo });
                // res.data.data.form_id.forEach(id => {
                //   this.jump('/modr_edit', {
                //     key: this.$UrlEncode.encode(
                //       JSON.stringify({
                //         perm_id: permId,
                //         form_id: id
                //       })
                //     )
                //   });
                // });
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
              }
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消生成'
          });
        });
    },
    clickCancel() {
      this.$emit('generateMtrbEmit');
    },
    suppSelectChange(index) {
      this.generateMtrlForm.splice(index, 0, this.copy[index]);
      this.copy = JSON.parse(JSON.stringify(this.generateMtrlForm));
    },
    mtrbApplyChange(index) {
      let row = this.generateMtrlForm[index];
      if (row.scon_mtrb_apply > row.scon_mtrb_num - row.scon_mtrb_applied) {
        row.scon_mtrb_apply = this.helper.calcPriceT(row.scon_mtrb_num - row.scon_mtrb_applied, 2, 1000000);
      } else {
        row.scon_mtrb_apply = this.helper.calcPriceT(row.scon_mtrb_apply);
      }
    }
  }
};
</script>

<style scoped>
::v-deep .showRow {
  display: none;
}
</style>
