<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div v-sticky class="vd_edhea vg_button_group" style="padding-top: 16px; background: white">
      <editHeader :isShow="isShow" :btn="btn" :strForm="sconForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('sconFormRef')" />
      <div v-if="isShow" class="vd_export" style="padding-top: 16px">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
      </div>
      <div class="vd_export2" style="padding-top: 16px">
        <span style="margin-right: 2px">{{ sconForm.scon_type === 1 ? '外销' : '内贸' }}合同号:</span>
        <el-input size="small" style="width: 150px" disabled v-model="sconForm.scon_no" maxlength="8" show-word-limit> </el-input>
      </div>
    </div>
    <el-form :model="sconForm" size="mini" ref="sconFormRef" :rules="sconFormRules" label-width="120px" :disabled="isShow">
      <el-row>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="变更类型" prop="order_type">
            <el-select @change="orderTypeChange" v-model="sconForm.order_type" placeholder="请选择变更类型">
              <el-option
                v-for="item in [
                  { value: '0', label: '取消订单' },
                  { value: '1', label: '成品明细变更' },
                  { value: '2', label: '其他' },
                  { value: '3', label: '新增内容' },
                  { value: '4', label: '删除货号' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-row>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="合同备注" prop="scon_no_remark">
              <el-input
                :disabled="sconDisabled.scon_no_remark"
                size="small"
                v-model="sconForm.scon_no_remark"
                @input="sconForm.scon_no_remark = helper.keepEngNum(sconForm.scon_no_remark)"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="合同日期" prop="scon_cndate">
              <el-date-picker v-model="sconForm.scon_cndate" type="date" placeholder="选择日期" :disabled="sconDisabled.scon_cndate"> </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="客户订单号" prop="scon_cust_no">
              <el-input
                v-model="sconForm.scon_cust_no"
                placeholder="请输入客户订单号"
                maxlength="20"
                show-word-limit
                clearable
                :disabled="sconDisabled.scon_cust_no"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="最终客户" prop="cust_babbr">
              <el-input v-model="sconForm.cust_babbr" placeholder="请选择最终客户" :disabled="sconDisabled.cust_babbr">
                <template slot="append">
                  <el-link class="vg_pointer" @click="clickSelectCust(2)" type="primary">双击选择</el-link>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="贸易方式" prop="cust_tradeway">
              <el-select v-model="sconForm.cust_tradeway" placeholder="请选择贸易方式" :disabled="sconDisabled.cust_tradeway">
                <el-option v-for="item in tradewayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="装运日期" prop="cust_creday">
              <el-date-picker
                value-format="timestamp"
                v-model="sconForm.scon_shdate"
                @change="val => (sconForm.scon_ppdate = val + sconForm.cust_creday * 86400000)"
                type="date"
                placeholder="选择日期"
                :disabled="sconDisabled.cust_creday"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="付款方式" prop="cust_payway">
              <el-select v-model="sconForm.cust_payway" @change="val => updatePpdate(val)" placeholder="请选择付款方式" :disabled="sconDisabled.cust_payway">
                <el-option v-for="item in custPaywayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="客户账期" prop="cust_creday">
              <el-input
                v-model="sconForm.cust_creday"
                placeholder="请输入客户账期"
                maxlength="3"
                show-word-limit
                @input="sconForm.cust_creday = helper.pureNumber(sconForm.cust_creday)"
                @change="val => (sconForm.scon_ppdate = sconForm.scon_shdate + val * 60 * 60 * 24 * 1000)"
                :disabled="sconDisabled.cust_creday"
              >
                <!--@change="shdateChange2"-->
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="预计收款日期" prop="scon_ppdate">
              <el-date-picker v-model="sconForm.scon_ppdate" type="date" placeholder="选择日期" :disabled="sconDisabled.scon_ppdate"> </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8" class="vg_mb_8" v-if="sconForm.scon_type === 1">
            <el-form-item label="起运港" prop="scon_sport">
              <el-select v-model="sconForm.scon_sport" placeholder="请选择起运港" :disabled="sconDisabled.scon_sport">
                <el-option v-for="item in custDportList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" class="vg_mb_8" v-if="sconForm.scon_type === 1">
            <el-form-item label="目的港" prop="cust_dport">
              <el-select v-model="sconForm.cust_dport" filterable placeholder="请选择目的港" :disabled="sconDisabled.cust_dport">
                <el-option v-for="item in custDportList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" class="vg_mb_8" v-if="sconForm.scon_type === 1">
            <el-form-item label="目的国" prop="cust_dcountry">
              <el-select v-model="sconForm.cust_dcountry" placeholder="请选择目的国" :disabled="sconDisabled.cust_dcountry">
                <el-option v-for="item in custCountryList" :key="item.id" :label="item.param2" :value="item.param2"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="货款金额" prop="scon_prtotal">
              <el-input v-model="sconForm.scon_prtotal" disabled maxlength="13" placeholder="暂无货款金额">
                <template slot="append">{{ sconForm.cust_currency === 'USD' ? '美元' : '元' }}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="费用金额" prop="scon_fetotal">
              <el-input v-model="sconForm.scon_fetotal" disabled maxlength="13" placeholder="暂无费用金额">
                <template slot="append">{{ sconForm.cust_currency === 'USD' ? '美元' : '元' }}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" class="vg_mb_8">
            <el-form-item label="合同金额" prop="scon_total">
              <el-input v-model="sconForm.scon_total" disabled maxlength="13" placeholder="暂无合同金额">
                <template slot="append">{{ sconForm.cust_currency === 'USD' ? '美元' : '元' }}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-col :md="24">
          <el-form-item label="装运备注" prop="ship_remarks">
            <el-input
              v-model="sconForm.ship_remarks"
              type="textarea"
              rows="3"
              placeholder="请输入"
              maxlength="500"
              show-word-limit
              clearable
              :disabled="sconDisabled.ship_remarks"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!--        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="客户简称" prop="cust_abbr">
            <el-input v-model="sconForm.cust_abbr" disabled placeholder="请选择客户">
              <template slot="append">
                <el-link :disabled="isShow" class="vg_pointer" @click="clickSelectCust(1)" type="primary">双击选择</el-link>
              </template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="销售所属部门" prop="cust_dept_id">
            <el-select v-model="sconForm.cust_dept_id" filterable @change="custDeptChange">
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item :label="`${sconForm.scon_type === 1 ? '外销' : '销售'}经办人`" prop="cust_stff_id">
            <el-select v-model="sconForm.cust_stff_id" placeholder="请选择" :disabled="!sconForm.cust_dept_id">
              <el-option v-for="item in stffUserList" :key="item.stff_id + item.stff_name" :label="item.stff_name" :value="item.stff_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="价格条款" prop="cust_paylaw">
            <el-select v-model="sconForm.cust_paylaw" placeholder="请选择价格条款">
              <el-option v-for="item in custPaylwayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="汇率" prop="scon_usdrate">
            <el-input
              :disabled="this.rateDisable"
              v-model="sconForm.scon_usdrate"
              placeholder="请输入汇率"
              maxlength="7"
              show-word-limit
              @input="sconForm.scon_usdrate = helper.keepTNum1(sconForm.scon_usdrate)"
              @change="sconForm.scon_usdrate = helper.retain2(sconForm.scon_usdrate, 4, 1000)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="交易币种" prop="cust_currency">
            <el-select v-model="sconForm.cust_currency" placeholder="请选择交易币种">
              <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="人民币金额" prop="scon_rmbtotal">
            <el-input v-model="sconForm.scon_rmbtotal" disabled placeholder="暂无人民币金额" maxlength="13">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="运输方式" prop="cust_shipway">
            <el-select v-model="sconForm.cust_shipway" placeholder="请选择运输方式">
              <el-option v-for="item in custShipwayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="保险条款" prop="scon_insure">
            <el-input v-model="sconForm.scon_insure" placeholder="请输入保险条款" maxlength="50" show-word-limit clearable> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="包装要求" prop="scon_pack">
            <el-input v-model="sconForm.scon_pack" placeholder="请输入包装要求" maxlength="50" show-word-limit clearable> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="不导入验货" prop="scon_inspection">
            <el-select v-model="sconForm.scon_inspection" placeholder="请选择">
              <el-option v-for="item in [{ value: 1, label: '是' }]" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="订单类别" prop="scon_category">
            <el-select v-model="sconForm.scon_category" placeholder="请选择">
              <el-option
                v-for="item in [
                  { value: '1', label: 'S(非常重要)' },
                  { value: '2', label: 'A(重要)' },
                  { value: '3', label: 'B(一般)' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="系列名" prop="scon_series_name">
            <el-input v-model="sconForm.scon_series_name" placeholder="请输入系列名" show-word-limit clearable> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="订单系列" prop="scon_order_series">
            <el-input v-model="sconForm.scon_order_series" placeholder="请输入订单系列" maxlength="50" show-word-limit clearable>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_8">
          <el-form-item label="目的地" prop="cust_bourm" v-if="sconForm.scon_type === 2">
            <el-input v-model="sconForm.cust_bourm" placeholder="请输入目的地" maxlength="50" show-word-limit clearable> </el-input>
          </el-form-item>
        </el-col>
      </el-row>-->
      <!--      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 1">
        <el-col :md="24">
          <el-form-item label="货物总称" prop="scon_bulkname">
            <el-input
              v-model="sconForm.scon_bulkname"
              type="textarea"
              rows="3"
              placeholder="请输入货物总称"
              maxlength="255"
              show-word-limit
              clearable
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 2">
        <el-col :md="24">
          <el-form-item label="交货及验收" prop="deli_and_check">
            <el-input
              v-model="sconForm.deli_and_check"
              type="textarea"
              rows="3"
              placeholder="请输入"
              maxlength="255"
              show-word-limit
              clearable
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="sconForm.scon_type === 2">
        <el-col :md="24">
          <el-form-item label="其他约定" prop="other_promi">
            <el-input
              v-model="sconForm.other_promi"
              type="textarea"
              rows="3"
              placeholder="请输入"
              maxlength="255"
              show-word-limit
              clearable
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>-->

      <el-row class="vg_mt_16 vd_bortop" v-if="tabPaneFlag">
        <el-col>
          <SconProdList
            :showFlagNumIdCopy="showFlagNumIdCopy"
            @confirmProdIdCopy="confirmProdIdCopy"
            ref="SconProdList"
            :sconForm="sconForm"
            :prodUnitList="prodUnitList"
            :showFlagNumId="showFlagNumId"
            @confirmProdId="confirmProdId"
            :addFlagId="addFlagId"
            @addFlagIdChange="addFlagIdChange"
            @sconProdPriceChange="sconProdPriceChange"
            @prodCurrentIndex="changeTransmitIndex"
            @changeMtrbList="index => $refs.sconMtrbList.prodMtrbPeruseChange(index)"
          ></SconProdList>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop" v-if="tabPaneFlag">
        <el-col>
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="部件明细" name="first" :key="'first'">
              <SconPartList
                :suppList="suppList"
                :prodUnitList="prodUnitList"
                :showFlagNumIdCopy="showFlagNumIdCopy"
                :sconForm="sconForm"
                :showFlagNumId="showFlagNumId"
              ></SconPartList>
            </el-tab-pane>
            <el-tab-pane label="材料明细" name="second" :key="'second'">
              <SconMtrbList
                ref="sconMtrbList"
                :suppList="suppList"
                :showFlagNumIdCopy="showFlagNumIdCopy"
                :transmitIndex="transmitIndex"
                :sconForm="sconForm"
                :showFlagNumId="showFlagNumId"
              ></SconMtrbList>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <el-tabs v-model="activeName2" type="border-card">
            <el-tab-pane label="费用明细" name="first" :key="'first'">
              <SconFeesList :sconForm="sconForm" @feetPriceChange="feetPriceChange" ref="sconFeeListRef" />
            </el-tab-pane>
            <el-tab-pane label="条款明细" name="second" :key="'second'">
              <SconClauInfo
                :isShow="isShow"
                :sconForm="sconForm"
                :cptt_bank_list="cptt_bank_list"
                @sconClauChange="sconClauChange"
                @sconClauChange2="sconClauChange2"
                :sconDisabled="sconDisabled"
              />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="生成成品采购合同" :visible.sync="dialogVisible" width="70%" class="vd_dialog">
      <generateParts
        ref="generateParts"
        :sconNo="sconForm.scon_no"
        :sconId="sconForm.scon_id"
        :generatePartForm="generatePartForm"
        @generateCancel="generateCancel"
      >
      </generateParts>
      <el-backtop target=".vd_dialog"></el-backtop>
    </el-dialog>
    <el-dialog title="生成采购材料合同" :visible.sync="dialogVisible1" width="70%" class="vd_dialog1">
      <generateMtrb
        :sconNo="sconForm.scon_no"
        :sconId="sconForm.scon_id"
        :generateMtrlForm="generateMtrlForm"
        @generateMtrbEmit="generateMtrbEmit"
      ></generateMtrb>
      <el-backtop target=".vd_dialog1"></el-backtop>
    </el-dialog>
    <el-row class="vg_mt_16">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm"></inputUser>
      </el-col>
    </el-row>
    <el-dialog title="客户信息" :visible.sync="dialogCustVisible" width="70%">
      <ImportCust @importCustChange="importCustChange" />
    </el-dialog>
    <div class="jumpDiv" v-show="jumpDialogVisible">
      <el-card style="height: 100%">
        <span class="el-dialog__title" style="margin-bottom: 16px">数量已修改请去生效的采购合同中变更操作</span>
        <div class="jumpDivClose">
          <el-link :underline="false" icon="el-icon-close" class="vg_cursor" @click="jumpDialogVisible = false"></el-link>
        </div>
        <div style="margin-top: 16px">
          <div v-if="podrMsgList">
            <span>成品采购合同</span>
            <div v-for="(item, index) in podrMsgList">
              <el-link @click="jumpPage({ permId: 127, id: item.podr_id })" class="vg_cursor">
                {{ index + 1 }}.{{ sconForm.scon_no + '-' + item.podr_id }}
              </el-link>
              <span> 我司货号{{ item.prod_no }}数量由</span>
              <span>{{ item.old_scon_part_num }}</span>
              <span>需改为</span>
              <span>{{ item.new_scon_part_num }}</span>
            </div>
          </div>
          <div v-if="modrMsgVos" style="margin-top: 16px">
            <span>材料采购合同</span>
            <div v-for="(item, index) in modrMsgVos">
              <el-link @click="jumpPage({ permId: 128, id: item.modr_id })" class="vg_cursor">
                {{ index + 1 }}.{{ sconForm.scon_no + '-' + item.modr_id }}
              </el-link>
              <span> 材料编号{{ item.mtrb_no }}数量由</span>
              <span>{{ item.old_scon_mtrb_num }}</span>
              <span>需改为</span>
              <span>{{ item.new_scon_mtrb_num }}</span>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { get, getNoCatch, postNoCatch } from '@api/request';
import { cpttAPI } from '@api/modules/comptitle';
import inputUser from '@/views/component/inputUser';
import editHeader from '@/views/component/editHeader';
import SconProdList from './Component/SconProdList.vue'; //成品明细
import SconPartList from './Component/SconPartList.vue'; //部件明细
import SconMtrbList from './Component/SconMtrbList.vue'; //材料明细
import SconFeesList from './Component/SconFeesList.vue'; //费用明细
import SconClauInfo from './Component/SconClauInfo'; //条款明细
import ImportCust from './Component/ImportCust.vue';
import { optnAPI } from '@api/modules/optn';
import { stffAPI } from '@/api/modules/staff';
import { sconAPI } from '@/api/modules/sconOrder';
import { suppAPI } from '@api/modules/supp';
import { deptAPI } from '@api/modules/department';
import generateParts from './Component/GenerateParts.vue';
import generateMtrb from './Component/GenerateMtrb.vue';
import { BigNumber } from 'bignumber.js';
import { requiredReminder } from '@assets/js/domUtils';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SconEditMain',
  components: {
    inputUser,
    editHeader,
    SconProdList,
    SconPartList,
    SconMtrbList,
    SconFeesList,
    SconClauInfo,
    ImportCust,
    generateParts,
    generateMtrb
  },
  data() {
    return {
      sconDisabled: {},
      jumpDialogVisible: false,
      modrMsgVos: [],
      podrMsgList: [],
      stffForm: {},
      isShow: true,
      btn: {},
      selectionsList: [],
      loadingFlag: true,
      sconForm: {
        scon_no: null, //外销合同编号
        scon_shipstat: null, //出运状态
        scon_cust_no: null, //客户订单号
        cust_id: null, //客户id
        cust_abbr: null, //客户简称
        cust_name: null, //客户全称
        cust_bid: null, //最终客户id
        cust_babbr: null, //最总选择客户简称
        scon_cndate: new Date(), //合同日期
        scon_shdate: null, //装运日期
        cust_stff_id: null, //外销经办人
        cust_tradeway: 'General Trade', //贸易方式
        cust_shipway: null, //运输方式
        cust_paylaw: null, //价格条款
        cust_payway: null, //付款方式
        cust_creday: null, //客户账期
        scon_ppdate: null, //预计收款日期
        scon_sport: '上海', //起运港
        cust_dport: null, //目的港
        cust_dcountry: null, //目的国
        scon_prtotal: null, //货款金额
        scon_fetotal: null, //费用金额
        scon_total: null, //合同金额
        cust_currency: null, //币种
        scon_usdrate: null, //美元汇率
        scon_rmbtotal: null, //本币金额 人民币
        scon_bulkname: '', //货物总称
        scon_insure: 'TO BE COVERED BY BUYER', //保险条款
        scon_pack: 'IN CARTONS', //包装要求
        scon_order_series: null, //订单系列
        scon_prod_list: [], //子表 商品明细
        scon_part_list: [], //子表 部件明细
        scon_mtrb_list: [], //子表 材料明细
        scon_fees_list: [], //子表 费用明细
        cust_dept_id: null, //外销所属部门 111111
        cptt_aid: null, //公司抬头id
        cptt_aname_en: null, //公司英文抬头
        cptt_bid: null, //收款抬头
        cust_shipmark: null, //唛头
        scon_remark: null, //备注
        clau_content: null, //条款内容
        inst_id: null, //货代id 机构
        inst_abbr: null, //机构名称
        inst_ename: null, //货代英文名称 机构
        cptt_bname_en: null, //收款抬头英文
        cptt_bank_name: null, //银行名称
        cptt_bank_account: null //银行信息
      },
      activeName: 'first',
      activeName2: 'first',
      dialogCustVisible: false,
      importCustType: null,
      stffUserList: [], //经办人
      tradewayList: [], //贸易方式
      custShipwayList: [], //运输方式
      custPaylwayList: [], //价格条款
      custPaywayList: [], //付款方式
      custDportList: [], //港
      custCountryList: [], //目的国
      custCurrencyList: [], //币种
      cptt_bank_list: [], //银行
      deptList: [],
      sconFormRules: {
        cust_abbr: [{ required: true, message: ' ', trigger: 'blur' }],
        order_type: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_type: [{ required: true, message: ' ', trigger: 'blur' }],
        // scon_usdrate: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_bourm: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_bid: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_cndate: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_shdate: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_tradeway: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_shipway: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_paylaw: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_payway: [{ required: true, message: ' ', trigger: 'blur' }],
        scon_sport: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_dept_id: [{ required: true, message: ' ', trigger: 'blur' }],
        ship_remarks: [{ required: false, message: ' ', trigger: 'blur' }]
      },
      showFlagNumId: 0,
      showFlagNumIdCopy: 0,
      transmitIndex: 0,
      addFlagId: -1,
      prodListCopy: [],
      partListCopy: [],
      mtrbListCopy: [],
      generatePartForm: [],
      generateMtrlForm: [],
      dialogVisible: false,
      dialogVisible1: false,
      suppList: [],
      prodUnitList: [],
      tabPaneFlag: false,
      sconTypeList: [
        { id: 1, label: '外贸' },
        { id: 2, label: '内贸' }
      ],
      rateDisable: false,
      calcSconFormTemp: 0,
      backUpOrderType: ''
    };
  },
  watch: {
    //获取合同金额
    'sconForm.scon_fetotal': function (newval, oldVal) {
      this.sconForm.scon_total = this.helper.haveFour(this.sconForm.scon_fetotal - 0 + (this.sconForm.scon_prtotal - 0));
    },
    'sconForm.scon_prtotal': function (newVal, oldVal) {
      this.sconForm.scon_total = this.helper.haveFour(this.sconForm.scon_fetotal - 0 + (this.sconForm.scon_prtotal - 0));
    },
    //获取人民币总金额
    'sconForm.scon_total': function (newVal, oldVal) {
      this.sconForm.scon_rmbtotal = this.helper.haveFour(this.sconForm.scon_usdrate * this.sconForm.scon_total);
    },
    'sconForm.scon_usdrate': function (newVal, oldVal) {
      this.sconForm.scon_rmbtotal = this.helper.haveFour(this.sconForm.scon_usdrate * this.sconForm.scon_total);
    },
    'sconForm.cust_currency'(newVal, oldVal) {
      if (newVal === 'RMB') {
        this.sconForm.scon_usdrate = 1;
        this.rateDisable = true;
      } else if (newVal === 'USD') {
        this.sconForm.scon_usdrate = 6.3;
        this.rateDisable = false;
      } else {
        this.sconForm.scon_usdrate = null;
      }
    },
    //新增和删除时计算成品价格
    'sconForm.scon_prod_list'() {
      let sum = 0;
      this.sconForm.scon_prod_list.forEach(item => {
        sum += item.prod_total_price - 0;
      });
      this.sconForm.scon_prtotal = this.helper.haveFour(sum);
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.loadingFlag = true;
      // const props = JSON.parse(UrlEncode.decode(this.$route.query.key))
      // if (props.form_id) {
      this.getSconInfo();
      // // }
      this.getTradeway();
      this.getCustShipway();
      this.getCustPayway();
      this.getCustPaylway();
      this.getCustDport();
      this.getContry();
      this.getCustCurrency();
      this.getDept();
      this.getSupp();
      this.getProdRankList();
      await this.initDisableForm();
    },
    initDisableForm() {
      for (let formKey in this.sconForm) {
        this.sconDisabled[formKey] = false;
      }
      this.sconForm.scon_prod_list.forEach(item => {
        item.disabled = false;
      });
      this.sconForm.scon_part_list.forEach(item => {
        item.disabled = false;
        item.prod_price_disabled = false;
      });
      this.sconForm.scon_mtrb_list.forEach(item => {
        item.disabled = false;
      });
      this.sconForm.scon_fees_list.forEach(item => {
        item.disabled = false;
      });
    },
    async changeTypeCommon(newVal) {
      await this.initDisableForm();
      setTimeout(() => {
        switch (newVal) {
          //取消订单全部禁用
          case '0':
            for (let formKey in this.sconForm) {
              this.sconDisabled[formKey] = true;
            }
            this.sconForm.scon_prod_list.forEach(item => {
              item.disabled = true;
            });
            this.sconForm.scon_part_list.forEach(item => {
              item.disabled = true;
              item.prod_price_disabled = true;
            });
            this.sconForm.scon_mtrb_list.forEach(item => {
              item.disabled = true;
            });
            this.sconForm.scon_fees_list.forEach(item => {
              item.disabled = true;
            });
            break;
          //成品明细变更/其他 除了部件单价禁用其他都不禁用
          case '1':
          case '2':
            for (let formKey in this.sconForm) {
              this.sconDisabled[formKey] = false;
            }
            this.sconForm.scon_prod_list.forEach(item => {
              item.disabled = false;
            });
            this.sconForm.scon_part_list.forEach(item => {
              item.disabled = false;
              item.prod_price_disabled = true;
            });
            this.sconForm.scon_mtrb_list.forEach(item => {
              item.disabled = false;
            });
            this.sconForm.scon_fees_list.forEach(item => {
              item.disabled = false;
            });
            break;
          case '3':
            //类型为新增内容时 主表全部禁用 新增的数据不禁用
            for (let formKey in this.sconForm) {
              this.sconDisabled[formKey] = true;
            }
            this.sconForm.scon_prod_list.forEach(item => {
              if (item.scon_orderprod_id) {
                item.disabled = true;
              }
            });
            this.sconForm.scon_part_list.forEach(item => {
              if (item.sconorder_id) {
                item.disabled = true;
                item.prod_price_disabled = true;
              }
            });
            this.sconForm.scon_mtrb_list.forEach(item => {
              if (item.scon_ordermtrb_id) {
                item.disabled = true;
              }
            });
            this.sconForm.scon_fees_list.forEach(item => {
              if (item.scon_orderfees_id) {
                item.disabled = true;
              }
            });
            break;
          case '4':
            for (let formKey in this.sconForm) {
              this.sconDisabled[formKey] = true;
            }
            this.sconForm.scon_prod_list.forEach(item => {
              item.disabled = true;
            });
            this.sconForm.scon_part_list.forEach(item => {
              item.disabled = true;
              item.prod_price_disabled = true;
            });
            this.sconForm.scon_mtrb_list.forEach(item => {
              item.disabled = true;
            });
            this.sconForm.scon_fees_list.forEach(item => {
              item.disabled = true;
            });
            break;
        }
        this.sconDisabled = cloneDeep(this.sconDisabled);
      }, 200);
    },
    orderTypeChange(val) {
      this.$confirm('变更类型将会重置数据!', '是否变更类型?', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(async () => {
          this.loadingFlag = true;
          this.getSconInfo(val);
        })
        .catch(() => {
          this.sconForm.order_type = this.backUpOrderType;
        });
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      });
    },
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    // 获取供应商
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = Array.from(res.data.data, ({ supp_id, supp_abbr }) => {
              return { supp_id: supp_id, supp_abbr: supp_abbr };
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    custDeptChange(val) {
      this.sconForm.cust_stff_id = null;
      this.getStffUser(val);
    },
    //获取经办人
    getStffUser(val) {
      get(stffAPI.getAllStffsV1, { dept_id: val })
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = Array.from(res.data.data, ({ stff_id, stff_name }) => {
              return { stff_id: stff_id, stff_name: stff_name };
            });
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取贸易方式
    getTradeway() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10016 }).then(({ data }) => {
        this.tradewayList = data.form.optn_cntt_list;
      });
    },
    // 获取单位
    getProdRankList() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10008 }).then(({ data }) => {
        this.prodUnitList = data.form.optn_cntt_list;
      });
    },
    // 获取运输方式
    getCustShipway() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10017 }).then(({ data }) => {
        this.custShipwayList = data.form.optn_cntt_list;
      });
    },
    // 获取价格条款
    getCustPaylway() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10014 }).then(({ data }) => {
        this.custPaylwayList = data.form.optn_cntt_list;
      });
    },
    //付款方式联动方法
    updatePpdate(val) {
      this.sconForm.cust_creday = Number(this.custPaywayList.find(({ param1 }) => val === param1).param2);
      this.sconForm.scon_ppdate = this.sconForm.scon_shdate + this.sconForm.cust_creday * 60 * 60 * 24 * 1000;
    },
    // 获取付款方式
    getCustPayway() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10013 }).then(({ data }) => {
        this.custPaywayList = data.form.optn_cntt_list;
      });
    },
    // 获取港
    getCustDport() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10018 }).then(({ data }) => {
        this.custDportList = data.form.optn_cntt_list;
      });
    },
    // 获取国家
    getContry() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10003 }).then(({ data }) => {
        this.custCountryList = data.form.optn_cntt_list;
      });
    },
    // 获取币种
    getCustCurrency() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10015 }).then(({ data }) => {
        this.custCurrencyList = data.form.optn_cntt_list;
      });
    },
    // 材料和部件部分
    // 获取公共prod_id
    confirmProdId(val) {
      this.showFlagNumId = val;
    },
    confirmProdIdCopy(val) {
      this.showFlagNumIdCopy = val;
    },
    changeTransmitIndex(val) {
      this.transmitIndex = val;
    },
    addFlagIdChange(val) {
      this.addFlagId = val;
    },

    //提交表单
    submit(formName) {
      let flag = this.sconForm.scon_part_list.findIndex(item => item.prod_mainsup === 1);
      if (flag >= 0) {
        this.$confirm('确认保存?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$refs[formName].validate((valid, object) => (valid ? this.saveInfo() : requiredReminder(object)));
          })
          .catch(() => {
            this.$message.info('已取消保存');
          });
      } else {
        this.$message.info('该合同没有主产品,不能保存');
      }
    },
    // 保存
    saveInfo() {
      let sconForm = JSON.parse(JSON.stringify(this.sconForm));
      sconForm.scon_cndate = this.helper.getNewDate(sconForm.scon_cndate); //合同日期
      sconForm.scon_shdate = this.helper.getNewDate(sconForm.scon_shdate); //装运日期
      sconForm.scon_ppdate = sconForm.scon_ppdate ? this.helper.getNewDate(sconForm.scon_ppdate) : 0; //预计收款日期

      // 成品、材料、部件
      // 成品
      this.prodListCopy = this.prodListCopy.filter(item => sconForm.scon_prod_list.every(item1 => item.scon_prod_id !== item1.scon_prod_id));
      for (let i in this.prodListCopy) {
        this.prodListCopy[i].destroy_flag = 1;
      }
      sconForm.scon_prod_list = sconForm.scon_prod_list.concat(this.prodListCopy);
      // 部件
      this.partListCopy = this.partListCopy.filter(item => sconForm.scon_part_list.every(item1 => item.scon_part_id !== item1.scon_part_id));
      for (let i in this.partListCopy) {
        this.partListCopy[i].destroy_flag = 1;
      }
      sconForm.scon_part_list = sconForm.scon_part_list.concat(this.partListCopy);
      // 材料
      this.mtrbListCopy = this.mtrbListCopy.filter(item => sconForm.scon_mtrb_list.every(item1 => item.scon_mtrb_id !== item1.scon_mtrb_id));
      for (let i in this.mtrbListCopy) {
        this.mtrbListCopy[i].destroy_flag = 1;
      }
      sconForm.scon_mtrb_list = sconForm.scon_mtrb_list.concat(this.mtrbListCopy);

      for (let i = 0; i < sconForm.scon_prod_list.length; i++) {
        let temp = 0;
        for (let j = 0; j < sconForm.scon_part_list.length; j++) {
          if (sconForm.scon_prod_list[i].scon_part_id === sconForm.scon_part_list[j].scon_part_id) {
            if (sconForm.scon_part_list[j].prod_mainsup === '1') {
              temp++;
            }
          }
        }
        if (temp > 1) {
          this.$message.warning('每一个成品只能包含一个为主厂的部件');
          break;
        }
      }
      sconForm.scon_fees_list.push(...this.$refs.sconFeeListRef.delList);
      postNoCatch(sconAPI.editScon, sconForm).then(({ data }) => {
        this.podrMsgList = [];
        this.modrMsgVos = [];
        // let { podrMsgList, modrMsgVos } = data;
        // if (podrMsgList.length > 0 || modrMsgVos.length > 0) {
        //   this.jumpDialogVisible = true;
        //   this.podrMsgList = podrMsgList;
        //   this.modrMsgVos = modrMsgVos;
        // }
        this.$message.success('保存成功!');
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
      });
    },

    //
    // handleSelectionChange(selection) {
    //   this.selectionsList = [];
    //   this.selectionsList = selection
    // },
    //获取表单信息
    getSconInfo(val) {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(sconAPI.getSconById, { sconorder_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.sconForm = res.data.data.form;
            this.backUpOrderType = this.sconForm.order_type;
            this.sconForm.scon_fees_list.forEach(item => {
              item.scon_fees_usd = this.helper.haveFour(item.scon_fees_usd);
            });
            this.sconForm.scon_cndate = this.sconForm.scon_cndate * 1000; //合同日期
            this.sconForm.scon_shdate = this.sconForm.scon_shdate * 1000; //装运日期
            this.sconForm.scon_usdrate = this.helper.haveFour(this.sconForm.scon_usdrate);
            this.prodListCopy = JSON.parse(JSON.stringify(this.sconForm.scon_prod_list));
            this.partListCopy = JSON.parse(JSON.stringify(this.sconForm.scon_part_list));
            this.mtrbListCopy = JSON.parse(JSON.stringify(this.sconForm.scon_mtrb_list));
            this.sconForm.scon_ppdate = this.sconForm.scon_ppdate === 0 ? null : this.sconForm.scon_ppdate * 1000; //预计收款日期
            this.calcSconFormTemp = cloneDeep(this.sconForm.scon_ppdate);
            // this.sconForm.scon_ppdate += this.sconForm.cust_creday * 60 * 60 * 24 * 1000;
            this.sconForm.scon_prtotal = this.helper.haveFour(this.sconForm.scon_prtotal);
            this.sconForm.scon_fetotal = this.helper.haveFour(this.sconForm.scon_fetotal);
            this.isShowInputer = true;
            this.stffForm.stff_name = this.sconForm.stff_name;
            this.stffForm.dept_name = this.sconForm.dept_name;
            this.stffForm.dept_team_name = this.sconForm.dept_team_name;
            this.stffForm.user_id = this.sconForm.user_id;
            this.stffForm.dept_id = this.sconForm.dept_id;
            this.stffForm.stff_id = this.sconForm.stff_id;
            this.stffForm.scon_pack = this.sconForm.scon_pack ? this.sconForm.scon_pack : '装箱';
            this.btn = res.data.data.btn;
            if (this.sconForm.scon_type === 0) {
              this.sconForm.scon_type = null;
            }
            this.calcForm();
            if (val) {
              this.sconForm.order_type = val;
              if (val === '0') {
                setTimeout(() => {
                  this.sconForm.scon_prod_list.forEach((item, index) => {
                    item.prod_num = 0;
                    this.$refs.SconProdList.prodTotalPrice(index);
                  });
                  this.sconForm.scon_fees_list.forEach(item => (item.scon_fees_usd = 0));
                  this.sconForm.scon_fetotal = 0;
                });
              }
            }
            setTimeout(() => {
              this.changeTypeCommon(this.sconForm.order_type);
            });
            setTimeout(() => {
              this.loadingFlag = false;
              this.getStffUser(this.sconForm.cust_dept_id);

              if (this.sconForm.cptt_aid !== 0) {
                this.getCpttBankByCptt();
              }
              setTimeout(() => {
                if (this.sconForm.scon_prod_list.length > 0) {
                  this.showFlagNumId = this.sconForm.scon_prod_list[0].prod_id;
                  this.showFlagNumIdCopy = this.sconForm.scon_prod_list[0].scon_prod_id;
                  this.$refs.SconProdList.getIndex = 0;
                }
              }, 100);
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.log(err));
    },
    // 调用银行接口
    getCpttBankByCptt() {
      get(cpttAPI.getCpttBankByCpttId, { cptt_id: this.sconForm.cptt_aid })
        .then(res => {
          if (res.data.code === 0) {
            this.cptt_bank_list = res.data.data.list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 计算内容
    calcForm() {
      for (let i = 0; i < this.sconForm.scon_prod_list.length; i++) {
        this.sconForm.scon_prod_list[i].prod_total_price = this.helper.haveFour(
          new BigNumber(this.sconForm.scon_prod_list[i].prod_usd).times(this.sconForm.scon_prod_list[i].prod_num).toNumber()
        );
        let temp = new BigNumber(this.sconForm.scon_prod_list[i].prod_num).div(this.sconForm.scon_prod_list[i].prod_qpc);
        if (temp.isFinite()) {
          this.sconForm.scon_prod_list[i].prod_box_num = temp.toFixed(0, 0);
        } else {
          this.sconForm.scon_prod_list[i].prod_box_num = null;
        }
        let calcNumO = new BigNumber(this.sconForm.scon_prod_list[i].prod_box_num);
        calcNumO = calcNumO.times(this.sconForm.scon_prod_list[i].prod_gweight).toNumber();
        this.sconForm.scon_prod_list[i].total_gross_weight = this.helper.reservedDigits(calcNumO);
        let calcNumT = new BigNumber(this.sconForm.scon_prod_list[i].prod_box_num);
        calcNumT = calcNumT.times(this.sconForm.scon_prod_list[i].prod_nweight).toNumber();
        this.sconForm.scon_prod_list[i].total_net_weight = this.helper.reservedDigits(calcNumT);
        let calcNumF = new BigNumber(this.sconForm.scon_prod_list[i].prod_box_num).times(this.sconForm.scon_prod_list[i].prod_vol);
        if (calcNumF.isFinite()) {
          this.sconForm.scon_prod_list[i].total_volume = calcNumF.toFixed(2);
        } else {
          this.sconForm.scon_prod_list[i].total_volume = null;
        }
        this.sconForm.scon_prod_list[i].prod_usd = this.helper.haveFour(this.sconForm.scon_prod_list[i].prod_usd);
        let prodId = this.sconForm.scon_prod_list[i].scon_prod_id;
        for (let j = 0; j < this.sconForm.scon_part_list.length; j++) {
          this.sconForm.scon_part_list[j].prod_price = this.helper.haveFour(this.sconForm.scon_part_list[j].prod_price);
          if (prodId === this.sconForm.scon_part_list[j].scon_prod_id) {
            this.sconForm.scon_part_list[j].prod_num = this.sconForm.scon_prod_list[i].prod_num;
            // this.sconForm.scon_part_list[j].scon_part_num = this.helper.haveFour(Number(this.sconForm.scon_part_list[j].prod_use)*100000*Number(this.sconForm.scon_part_list[j].prod_num)/100000)
            this.sconForm.scon_part_list[j].total_price = this.helper.haveFour(
              (Number(this.sconForm.scon_part_list[j].scon_part_apply) * 100000 * Number(this.sconForm.scon_part_list[j].prod_price)) / 100000
            );
          }
          if (this.sconForm.scon_part_list[j].supp_id === 0) {
            this.sconForm.scon_part_list[j].supp_id = null;
          }
          this.sconForm.scon_part_list[j].surplus_price =
            (Number(this.sconForm.scon_part_list[j].scon_part_num) * 100000 -
              Number(this.sconForm.scon_part_list[j].scon_part_applied) * 100000 -
              Number(this.sconForm.scon_part_list[j].scon_part_apply) * 100000) /
            100000;
        }
        for (let j = 0; j < this.sconForm.scon_mtrb_list.length; j++) {
          if (prodId === this.sconForm.scon_mtrb_list[j].scon_prod_id) {
            this.sconForm.scon_mtrb_list[j].prod_num = this.sconForm.scon_prod_list[i].prod_num;
            // this.sconForm.scon_mtrb_list[j].prod_mtrb_peruse = this.helper.reservedDigits(Number(this.sconForm.scon_mtrb_list[j].prod_mtrb_use)*100000/Number(this.sconForm.scon_mtrb_list[j].prod_mtrb_per)/100000)
            this.sconForm.scon_mtrb_list[j].mtrb_total_price = this.helper.haveFour(
              (Number(this.sconForm.scon_mtrb_list[j].scon_mtrb_num) * 100000 * Number(this.sconForm.scon_mtrb_list[j].prod_mtrb_price)) / 100000
            );
          }
          this.sconForm.scon_mtrb_list[j].scon_mtrb_num = this.helper.reservedDigits(this.sconForm.scon_mtrb_list[j].scon_mtrb_num);
          this.sconForm.scon_mtrb_list[j].scon_mtrb_applied = this.helper.reservedDigits(this.sconForm.scon_mtrb_list[j].scon_mtrb_applied);
          this.sconForm.scon_mtrb_list[j].scon_mtrb_apply = this.helper.reservedDigits(this.sconForm.scon_mtrb_list[j].scon_mtrb_apply);
          this.sconForm.scon_mtrb_list[j].prod_mtrb_price = this.helper.haveFour(this.sconForm.scon_mtrb_list[j].prod_mtrb_price);
          // this.sconForm.scon_mtrb_list[j].surplus_price = this.helper.reservedDigits(
          //   (Number(this.sconForm.scon_mtrb_list[j].scon_mtrb_num) * 100000 -
          //     Number(this.sconForm.scon_mtrb_list[j].scon_mtrb_applied) * 100000 -
          //     Number(this.sconForm.scon_mtrb_list[j].scon_mtrb_apply) * 100000) /
          //     100000
          // );
        }
      }
      // setTimeout(() => {
      this.tabPaneFlag = true;
      // }, 500);
    },
    //选择客户
    clickSelectCust(val) {
      this.importCustType = val;
      this.dialogCustVisible = true;
    },
    //双击选中 客户
    importCustChange(row) {
      if (this.importCustType === 2) {
        this.sconForm.cust_babbr = row.cust_abbr; //最终选择客户 简称
        this.sconForm.cust_bid = row.cust_id; //最终选择客户 id
      } else {
        this.sconForm.cust_dept_id = row.cust_dept_id;
        this.sconForm.cust_abbr = row.cust_abbr; //客户简称
        this.sconForm.cust_id = row.cust_id; //客户id
        this.sconForm.cust_babbr = row.cust_abbr; //最终选择客户 简称
        this.sconForm.cust_bid = row.cust_id; //最终选择客户 id
        this.sconForm.cust_tradeway = row.cust_tradeway; //贸易方式
        this.sconForm.cust_shipway = row.cust_shipway; //运输方式
        this.sconForm.cust_paylaw = row.cust_paylaw; //价格条款
        this.sconForm.cust_payway = row.cust_payway; //付款方式
        this.sconForm.cust_creday = row.cust_creday; //客户账期
        this.sconForm.cust_dport = row.cust_dport; //目的港
        this.sconForm.cust_dcountry = row.cust_country_eng; //目的国
        this.sconForm.cust_currency = row.cust_currency; //币种
        this.sconForm.cptt_aid = row.cptt_aid; //公司抬头id
        this.sconForm.cptt_aname_en = row.cptt_aname; //公司抬头
        this.sconForm.cptt_bname_en = row.cptt_bname; //公司抬头
        this.sconForm.cptt_bid = row.cptt_bid; // 收款抬头id
        this.sconForm.cust_shipmark = row.cust_shipmark; //唛头
        this.sconForm.inst_id = row.inst_id;
        this.sconForm.inst_abbr = row.inst_abbr;
        this.sconForm.inst_ename = row.inst_ename;
        if (row.cust_stff_id) {
          this.sconForm.cust_stff_id = row.cust_stff_id;
        } else {
          this.sconForm.cust_stff_id = null;
        }
        this.getStffUser(this.sconForm.cust_dept_id);
        if (row.cptt_aid !== 0) {
          this.getCpttBankByCptt();
          // get(cpttAPI.getCpttBankByCpttId, { cptt_id: row.cptt_aid })
          // .then((res) => {
          //   if (res.data.code === 0) {
          //     this.cptt_bank_list = res.data.data.list;
          //     return;
          //   }
          //   this.$message.error(res.data.msg);
          // })
          // .catch((res) => {
          //   this.$message.error(res.data.msg);
          // });
        }
      }
      this.dialogCustVisible = false;
    },
    //装运日期选择
    shdateChange(evt) {
      if (this.sconForm.cust_creday) {
        this.sconForm.scon_ppdate = evt.getTime() + this.sconForm.cust_creday * 86400000;
      } else {
        this.sconForm.scon_ppdate = evt;
      }
    },
    // 客户账期选择联动影响收款日期
    shdateChange2() {
      let evt = null;
      let type = Object.prototype.toString.call(this.sconForm.scon_shdate);
      if (type != '[object Date]') {
        evt = new Date(this.sconForm.scon_shdate);
      } else {
        evt = this.sconForm.scon_shdate;
      }
      this.shdateChange(evt);
    },
    //条款内容变化
    sconClauChange(row) {
      this.sconForm.clau_content = row.clau_content;
    },
    //机构信息变化
    sconClauChange2(row) {
      this.sconForm.inst_id = row.inst_id;
      this.sconForm.inst_ename = row.inst_ename;
      this.sconForm.inst_abbr = row.inst_abbr;
    },
    //费用金额 变化
    feetPriceChange(val) {
      this.sconForm.scon_fetotal = this.helper.haveFour(val);
    },
    buttonRefresh() {
      this.initData();
    },
    sconProdPriceChange() {
      let sum = 0;
      this.sconForm.scon_prod_list.forEach(item => {
        sum += item.prod_total_price - 0;
      });
      this.sconForm.scon_prtotal = this.helper.haveFour(sum);
    },
    // 生成成品部件合同
    async generatePart() {
      // let temp = JSON.parse(JSON.stringify(this.sconForm.scon_part_list))
      // let temp1 = [];
      // for (let i=0;i<temp.length;i++) {
      //   temp[i].prod_mainsup = temp[i].prod_mainsup + ''
      //   let flag = false;
      //   let ind = 0;
      //   for(let j=0;j<temp1.length;j++){
      //     if(temp[i].supp_id === temp1[j].supp_id){
      //       flag = true;
      //       ind = j
      //     }
      //   }
      //   if(Number(temp[i].scon_part_apply) !== 0){
      //     if(flag){
      //       temp1.splice(ind,0,temp[i])
      //     }else{
      //       temp1.push(temp[i])
      //     }
      //   }
      // }
      // this.generatePartForm = temp1;

      await this.confirmPart();
      this.$refs.generateParts.getScons();
    },
    confirmPart() {
      this.loadingFlag = false;
      this.dialogVisible = true;
    },
    // 取消生成成品部件合同
    generateCancel() {
      this.dialogVisible = false;
    },
    // 生成材料合同
    generateMtrb() {
      let temp = JSON.parse(JSON.stringify(this.sconForm.scon_mtrb_list));
      let temp1 = [];
      temp.forEach(item => {
        this.sconForm.scon_prod_list.forEach(item2 => {
          if (item.prod_id === item2.prod_id) {
            item.prod_no = item2.prod_no;
            item.prod_poststfix = item2.prod_poststfix;
            item.prod_cust_no = item2.prod_cust_no;
          }
        });
      });
      for (let i = 0; i < temp.length; i++) {
        let flag = false;
        let ind = 0;
        for (let j = 0; j < temp1.length; j++) {
          if (temp[i].supp_id === temp1[j].supp_id) {
            flag = true;
            ind = j;
          }
        }
        if (Number(temp[i].scon_mtrb_apply) !== 0) {
          if (flag) {
            temp1.splice(ind, 0, temp[i]);
          } else {
            temp1.push(temp[i]);
          }
        } else {
          this.$message.warning('请填写成品数量');
        }
      }
      this.generateMtrlForm = temp1;
      this.dialogVisible1 = true;
    },
    generateMtrbEmit() {
      this.dialogVisible1 = false;
    },

    // 打印
    clickPrint(val) {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loadingFlag = true;
          get(sconAPI.exportSconPdfById, { scon_id: this.sconForm.scon_id, versions: val ? val : 1 })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }
                this.loadingFlag = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.loadingFlag = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.loadingFlag = false;
            });
        })
        .catch(() => {});
    },
    clickExportExcel(val) {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          get(sconAPI.exportSconPdfById, { scon_id: this.sconForm.scon_id, type: 1, versions: val ? val : 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExport(val) {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          get(sconAPI.exportSconPdfById, { scon_id: this.sconForm.scon_id, versions: val ? val : 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, `${res.data.data.fileName}.pdf`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => this.$message.error(err));
        })
        .catch(() => {});
    },
    jumpPage(val) {
      this.jump(`/${val.permId === 128 ? 'm' : 'p'}odr_edit`, {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: val.permId,
            form_id: val.id
          })
        )
      });
    },
    changeCurrency(type) {
      this.sconForm.cust_currency = type;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_edhea {
  position: relative;
}
.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.jumpDiv {
  z-index: 2001;
  position: absolute;
  top: 5%;
  left: 30%;
  width: 500px;
}

.jumpDivClose {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
